'use strict';

import {async} from 'regenerator-runtime';
import { API_URL, SEARCH_URL, KEY, RES_PER_PAGE } from './config';
// import { getJSON, sendJSON } from './helper';
import { AJAX } from './helper.js'

export const state = {
    recipe: {},
    search: {
        query: '',
        results: [],
        page: 1,
        resultsPerPage: RES_PER_PAGE,
    },
    bookmarks: [],
};

const createRecipeObject = function(data) {
    const { recipe } = data.data;
    return {
        id: recipe.id,
        title: recipe.title,
        publisher: recipe.publisher,
        sourceUrl: recipe.source_url,
        image: recipe.image_url,
        servings: recipe.servings,
        cookingTime: recipe.cooking_time,
        ingredients: recipe.ingredients,
        ...(recipe.key && {key: recipe.key}),
    };
};

export const loadRecipe = async (id) => {
    try {
        const data = await AJAX(`${API_URL}${id}?key=${KEY}`)
        state.recipe = createRecipeObject(data)

        if(state.bookmarks.some(bookmark => bookmark.id === id)) {
            state.recipe.bookmarked = true;
        } else {
            state.recipe.bookmarked = false;
        }

        console.log(state.recipe)
    } catch(err) {
        throw err;
    }
}

export const loadSearchResults = async function(query) {
    try {
        state.search.query = query;
        const data = await AJAX(`${API_URL}?search=${query}&key=${KEY}`)

        state.search.results = data.data.recipes.map(rec => {
            return {
                id: rec.id,
                title: rec.title,
                publisher: rec.publisher,
                image: rec.image_url,
                ...(rec.key && {key: rec.key}),
            }
        })
        state.search.page = 1;
    } catch (err) {
        throw err;
    }
}

export const getSearchResultsPage = function (page = state.search.page) {
    state.search.page = page;

    const start = (page - 1) * state.search.resultsPerPage; // 0
    const end = page * state.search.resultsPerPage // 9

    return state.search.results.slice(start, end)
}

export const updateServings = function (newServings = 1) {
    state.recipe.ingredients.forEach(ing => {
        ing.quantity = (ing.quantity * newServings) / state.recipe.servings;
    });

    state.recipe.servings = newServings;
}

const persistBookmarks = function () {
    localStorage.setItem('bookmarks', JSON.stringify(state.bookmarks))
}

export const addBookmark = function(recipe) {
    // Add Bookmark
    if (!state.bookmarks.includes(recipe)) {
        state.bookmarks.push(recipe);
        state.recipe.bookmarked = true;
    }
    if (recipe.id === state.recipe.id) state.recipe.bookmarked = true

    persistBookmarks();
}

export const deleteBookmark = function(id) {
    const index = state.bookmarks.findIndex(el => el.id === id);
    state.bookmarks.splice(index, 1);

    if (id === state.recipe.id) 
    state.recipe.bookmarked = false;

    persistBookmarks();
}

const init = function() {
    const storage = localStorage.getItem('bookmarks');
    if(storage) state.bookmarks = JSON.parse(storage);
}();

const clearBookmarks = function() {
    localStorage.clear('bookmarks');
}
// clearBookmarks();

export const uploadRecipe = async function(newRecipe) {
    try {
        const ingredients = Object.entries(newRecipe).filter(ing => ing[0].startsWith('ingredient') && ing[1] !== '').map(ing => {
            const ingArr = ing[1].split(',').map(el => el.trim())
            // const ingArr = ing[1].replaceAll(' ', '').split(',');
            if (ingArr.length !== 3)
            throw new Error(
                `Wrong ingredient format! Please use format your ingredient as '#, unit, Ingredient'`
            )
    
    
            const [quantity, unit, description] = ingArr;
            
            return { quantity: quantity ? +quantity : null, unit, description }
        })
        const recipe = {
            title: newRecipe.title,
            publisher: newRecipe.publisher,
            source_url: newRecipe.sourceUrl,
            image_url: newRecipe.image,
            servings: +newRecipe.servings,
            cooking_time: +newRecipe.cookingTime,
            ingredients,
        }
        const data = await AJAX(`${API_URL}?key=${KEY}`, recipe)
        state.recipe = createRecipeObject(data);
        addBookmark(state.recipe);
    } catch(err) {
        throw err;
    }
}